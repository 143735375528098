import {ReactNode, useMemo} from 'react';

import {AbilityBuilder, createMongoAbility} from '@casl/ability';
import useSWR from 'swr';

import PermissionsContext, {AppAbilities} from '@src/contexts/permissions';
import {useCMSClient} from '@src/hooks/useCMSClient';
import {CMSClient} from '@tetra-next/graphql-client';

type Role = Awaited<
  ReturnType<CMSClient['getCurrentOrganizationMember']>
>['currentOrganizationMember']['attributes']['organizationRole']['data'];

const defaultRoles: Role[] = [
  {
    attributes: {
      name: 'Viewer',
    },
  },
  {
    attributes: {
      name: 'Creator',
      manageMaterials: true,
      manageSupportingFiles: true,
      manageInterviews: true,
      manageProjects: true,
      manageTags: true,
      manageAssetFields: true,
      manageSnippets: true,
      exportData: true,
      share: true,
      manageInsights: true,
      useAI: true,
      inviteUser: true,
    },
  },
  {
    attributes: {
      name: 'Admin',
      audit: true,
      manageProjectFields: true,
      manageUsers: true,
      universalSearch: true,
      manageRoles: true,
      manageTeams: true,
      manageTemplates: true,
      manageProjects: true,
      manageMaterials: true,
      manageTags: true,
      manageAssetFields: true,
      manageSupportingFiles: true,
      manageInterviews: true,
      manageSnippets: true,
      exportData: true,
      share: true,
      manageInsights: true,
      useAI: true,
      inviteUser: true,
    },
  },
];

type Props = {
  children: ReactNode;
};

export default function PermissionsProvider({children}: Props) {
  const CMSClient = useCMSClient();

  const {data} = useSWR(['getCurrentOrganizationMemberRole'], async () => {
    const data = await CMSClient.getCurrentOrganizationMember();
    return data.currentOrganizationMember.attributes;
  });

  const role = useMemo(() => {
    if (data?.role == 'admin') {
      return defaultRoles.find((role) => {
        return role.attributes.name == 'Admin';
      });
    }
    if (data?.role == 'creator') {
      return defaultRoles.find((role) => {
        return role.attributes.name == 'Creator';
      });
    }
    if (data?.role == 'viewer') {
      return defaultRoles.find((role) => {
        return role.attributes.name == 'Viewer';
      });
    }
    return data?.organizationRole?.data;
  }, [data]);

  const permissions = useMemo(() => {
    const builder = new AbilityBuilder<AppAbilities>(createMongoAbility);

    if (!role) {
      return builder.build();
    }

    if (role.attributes.audit) {
      builder.can('audit', 'all');
    }
    if (role.attributes.manageProjects) {
      builder.can('manage', 'projects');
    }
    if (role.attributes.manageSnippets) {
      builder.can('manage', 'snippets');
    }
    if (role.attributes.manageTags) {
      builder.can('manage', 'tags');
    }
    if (role.attributes.exportData) {
      builder.can('export', 'all');
    }
    if (role.attributes.manageInsights) {
      builder.can('manage', 'insights');
    }
    if (role.attributes.manageInterviews) {
      builder.can('manage', 'assets');
    }
    if (role.attributes.manageMaterials) {
      builder.can('manage', 'materials');
    }
    if (role.attributes.manageProjectFields) {
      builder.can('manage', 'projectFields');
    }
    if (role.attributes.manageRoles) {
      builder.can('manage', 'roles');
    }
    if (role.attributes.manageSupportingFiles) {
      builder.can('manage', 'files');
    }
    if (role.attributes.manageTeams) {
      builder.can('manage', 'teams');
    }
    if (role.attributes.manageTemplates) {
      builder.can('manage', 'templates');
    }
    if (role.attributes.manageUsers) {
      builder.can('manage', 'users');
    }
    if (role.attributes.share) {
      builder.can('share', 'all');
    }
    if (role.attributes.useAI) {
      builder.can('useAi', 'all');
    }
    if (role.attributes.inviteUser) {
      builder.can('invite', 'all');
    }
    if (role.attributes.manageAssetFields) {
      builder.can('manage', 'assetFields');
    }

    return builder.build();
  }, [role]);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
}
